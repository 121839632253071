


































































































































































































































import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@movecloser/ui-core'

import {
  IconSelect,
  Identifier,
  Inject,
  IRelatedService,
  PickerSelectionCallback,
  PossibleRelatedPicker,
  PossibleRelatedType,
  Related,
  RelatedServiceType
} from '../../../backoffice'

import { SnippetForm } from '../../../../src/modules/partials/SnippetForm'

import { FormInput, FormSelect, FormDateTimePicker } from '../../shared/components/form'

import { ContentRepositoryType, ContentType, IContentRepository } from '../../content/contracts'

import { NavigationItem, OpenStrategy, Target } from '../contracts'

@Component<NavigationItemEdit>({
  name: 'NavigationItemEdit',
  components: { FormInput, FormSelect, FormDateTimePicker, IconSelect, SnippetForm },

  created () {
    this.init(this.item)
  }
})
export class NavigationItemEdit extends Vue {
  @Prop({ type: Object, required: true })
  public item!: NavigationItem

  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public Icons = DashmixIconName
  public Target = Target

  public form = 'editNavigationItem'
  public isLoading: boolean = false
  public label: string = ''
  public icon: string = ''
  public link: string | null = null
  public linkName: string | null = null
  public secondaryLabel: string | null = null
  public snippet: Related | null = null
  public strategy: OpenStrategy = OpenStrategy.Self
  public type: Target | null = Target.Internal
  public withIcon: boolean = false
  public withSecondaryLabel: boolean = false
  public hasAdditionalClass: boolean = false
  public hasColor: boolean = false
  public colorCode: string | null = null
  public hasSnippet: boolean = false
  public additionalClass: string | null = null
  public hasBadge: boolean = false
  public badge: string | null = null
  public badgeExpiresAt: string | null = null

  public iconOptions = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'youtube', label: 'YouTube' },
    { value: 'instagram', label: 'Instagram' }
  ]

  public get isBlank () {
    return this.strategy === OpenStrategy.Blank
  }

  public set isBlank (value: boolean) {
    this.strategy = value ? OpenStrategy.Blank : OpenStrategy.Self
  }

  public get isValid () {
    return (this.type === null || !!this.link) && !!this.label
  }

  public cancel () {
    this.$emit('cancel')
  }

  public async selectContent () {
    this.modalConnector.open(PossibleRelatedPicker.Link, {
      config: {},
      onClose: () => this.modalConnector.close(),
      onSelection: async (data: Related) => {
        this.link = `${data.value}`

        const page: AnyObject = await this.relatedService.describe(
          { type: PossibleRelatedType.Link, value: data.value }
        )

        this.linkName = page.title
      },
      selected: this.link ? { type: PossibleRelatedType.Link, value: this.link } : null
    }, { size: SizeMap.Large })
  }

  public pickRelated (
    picker: string,
    onSelection: PickerSelectionCallback,
    selected?: Related,
    options?: AnyObject
  ) {
    this.modalConnector.open(picker, {
      config: options,
      onClose: () => this.modalConnector.close(),
      onSelection,
      selected
    }, { size: SizeMap.Large })
  }

  public update () {
    if (this.type === null || this.link) {
      const item: NavigationItem = {
        strategy: this.strategy,
        target: this.type ? {
          type: this.type,
          link: this.link ?? ''
        } : null,
        label: this.label,
        icon: this.withIcon ? this.icon : '',
        children: this.item.children,
        colorCode: this.hasColor ? (this.colorCode ?? '') : undefined,
        secondaryLabel: this.withSecondaryLabel ? this.secondaryLabel ?? '' : undefined,
        additionalClass: this.hasAdditionalClass ? (this.additionalClass ?? '') : undefined,
        snippet: this.hasSnippet ? (this.snippet ?? undefined) : undefined,
        badge: this.hasBadge ? (this.badge ?? undefined) : undefined,
        badgeExpiresAt: this.hasBadge ? (this.badgeExpiresAt ?? undefined) : undefined,
        hasParent: this.item.hasParent
      }

      this.$emit('update', item)
    }
  }

  @Watch('item')
  private async init (editedItem: NavigationItem) {
    this.additionalClass = editedItem?.additionalClass ?? null
    this.colorCode = editedItem?.colorCode ?? null
    this.hasAdditionalClass = !!this.additionalClass
    this.label = editedItem?.label ?? ''
    this.strategy = editedItem?.strategy ?? OpenStrategy.Self
    this.type = editedItem?.target ? editedItem?.target?.type ?? Target.Internal : null
    this.icon = editedItem?.icon ?? ''
    this.hasColor = !!this.colorCode
    this.withIcon = !!this.icon
    this.secondaryLabel = editedItem?.secondaryLabel ?? null
    this.withSecondaryLabel = !!this.secondaryLabel
    this.snippet = editedItem?.snippet ?? null
    this.hasSnippet = !!this.snippet
    this.badge = editedItem?.badge ?? null
    this.badgeExpiresAt = editedItem?.badgeExpiresAt ?? null
    this.hasBadge = !!this.badge || !!this.badgeExpiresAt
    this.link = editedItem?.target?.link ?? null
    this.linkName = editedItem?.target?.type === Target.Internal && editedItem?.target?.link
      ? await this.loadPageTitle(Number(editedItem?.target?.link))
      : null
  }

  private loadPageTitle (id: Identifier): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.contentRepository.loadNode(ContentType.Page, id, {})
        .then(model => resolve(model.title))
        .catch(e => reject(e))
    })
  }
}

export default NavigationItemEdit
